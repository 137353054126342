
import { useState, useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import Branding from './Branding';
import useUnmountRef from '../hooks/useUnmountRef';

export default function Sidebar({ routes, showRef }) {
  const unmountRef = useUnmountRef();
  const [show, setShow] = useState(false);

  showRef.current = setShow;

  const closeSidebar = useCallback(() => {
    setTimeout(() => {
      if (unmountRef.current) return;
      setShow(false);
    }, 100);
  }, [unmountRef]);

  return (
    <aside
      id="sidenav-open"
      className={show ? " sidenav-open" : ""}
      style={{ maxHeight: '100vh', overflow: 'scroll' }}
    >
      <nav className="bg-white dark:bg-gray-700 shadow-lg pb-4" style={{ minWidth: '180px' }}>
        <div className="flex items-center justify-center pt-6">
          <Branding logo={true} logoProps={{ size: 60 }} />
        </div>
        <div className="mt-6">
          <div>
            {routes.map(route => (
              <NavLink
                key={route.path}
                to={route.path}
                onClick={closeSidebar}
                className={({ isActive }) => {
                  if (isActive) {
                    return "font-bold text-green-500 flex items-center p-2 my-2 transition-colors duration-200 justify-start bg-gradient-to-r from-white to-green-100 border-r-4 border-green-500 dark:from-gray-700 dark:to-gray-800 border-r-4 border-green-500";
                  }
                  return "text-gray-500 dark:text-gray-200 flex items-center p-2 my-2 transition-colors duration-200 justify-start hover:text-green-500 hover:bg-gray-50";
                }}
                // activeClassName="font-bold text-green-500 flex items-center p-2 my-2 transition-colors duration-200 justify-start bg-gradient-to-r from-white to-green-100 border-r-4 border-green-500 dark:from-gray-700 dark:to-gray-800 border-r-4 border-green-500"
                // className="text-gray-500 dark:text-gray-200 flex items-center p-2 my-2 transition-colors duration-200 justify-start hover:text-green-500 hover:bg-gray-50"
              >
                {route.icon && (
                  <span className="text-left select-none">
                    {route.icon}
                  </span>
                )}
                <span className="mx-4 text-sm select-none">
                  {route.title}
                </span>
              </NavLink>
            ))}
          </div>
        </div>
      </nav>
      <button
        href="#"
        title="Close Menu"
        aria-label="Close Menu"
        id="sidenav-close"
        className="outline-none focus:outline-none"
        onClick={() => setShow(false)}
      ></button>
    </aside>
  );
}