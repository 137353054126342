import { action } from './common';

const BASE = 'APP/';

export const LOADING_START = `${BASE}LOADING_START`;
export const LOADING_STOP = `${BASE}LOADING_STOP`;
export const startLoading = ({ title, animate = false } = {}) => action(LOADING_START, { title, animate });
export const stopLoading = () => action(LOADING_STOP);

export const MESSAGE_RECEIVED = `${BASE}MESSAGE_RECEIVED`;
export const messageReceived = (value) => action(MESSAGE_RECEIVED, value);

export const SET_ERROR = `${BASE}SET_ERROR`;
export const setError = (error) => action(SET_ERROR, error);

export const REGISTER_HEADER = `${BASE}REGISTER_HEADER`;
export const registerHeader = (id, { title, action: actionObj }) => action(REGISTER_HEADER, { id, title, action: actionObj });
export const CLEAR_HEADER = `${BASE}CLEAR_HEADER`;
export const clearHeader = (id) => action(CLEAR_HEADER, id);