import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { Route, Navigate, Routes } from 'react-router-dom';
import Header from '../components/Header';
// import Loading from '../components/Loading';
import ErrorPage from './ErrorPage';
import { URLS } from '../constants';
import SidebarNav from '../components/SidebarNav';
import './App.css';
import {
  BarChart2 as BarChart2Icon,
  Grid as GridIcon,
  Settings as SettingsIcon,
  Users as UsersIcon,
  CheckCircle as CheckCircleIcon,
  MapPin as MapPinIcon,
  DollarSign as DollarSignIcon,
  UserCheck as UserCheckIcon,
  Box as BoxIcon
} from 'react-feather';

import { useAuth0 } from '@auth0/auth0-react';
import Login from './Login';

const ICON_SIZE = 16;

const Metrics = React.lazy(() => import('./Metrics'));
const Dashboards = React.lazy(() => import('./Dashboards'));
const Users = React.lazy(() => import('./Users'));
const ApproveMembershipPictures = React.lazy(() => import('./ApproveMembershipPictures'));
const Subscriptions = React.lazy(() => import('./Subscriptions'));
const CheckIns = React.lazy(() => import('./CheckIns'));
const Busers = React.lazy(() => import('./Busers'));
const Sites = React.lazy(() => import('./Sites'));
const Accesses = React.lazy(() => import('./Accesses'));
const Activities = React.lazy(() => import('./Activities'));
const Discounts = React.lazy(() => import('./Discounts'));
const Settings = React.lazy(() => import('./Settings'));

const routes = [
  {
    title: 'Metrics',
    path: URLS.METRICS,
    component: <Metrics />,
    icon: (<BarChart2Icon size={ICON_SIZE} />)
  },
  {
    title: 'Dashboards',
    path: URLS.DASHBOARDS,
    component: <Dashboards />,
    icon: (<GridIcon size={ICON_SIZE} />)
  },
  {
    title: 'Users',
    path: URLS.USERS,
    component: <Users />,
    icon: (<UsersIcon size={ICON_SIZE} />)
  },
  {
    title: 'Membership Pictures',
    path: URLS.MEMBERSHIP_PICTURES_APPROVE,
    component: <ApproveMembershipPictures />,
    icon: (<UserCheckIcon size={ICON_SIZE} />)
  },
  {
    title: 'Subscriptions',
    path: URLS.SUBSCRIPTIONS,
    // header: false,
    component: <Subscriptions />,
    icon: (<DollarSignIcon size={ICON_SIZE} />)
  },
  {
    title: 'Check Ins',
    path: URLS.CHECKINS,
    component: <CheckIns />,
    icon: (<CheckCircleIcon size={ICON_SIZE} />)
  },
  // {
  //   title: 'Businesses',
  //   path: URLS.BUSINESSES,
  //   component: Businesses,
  //   icon: <BusinessIcon style={MaterialUIIconStyle} />
  // },
  {
    title: 'Business Users',
    path: URLS.BUSERS,
    component: <Busers />,
    icon: (<UsersIcon size={ICON_SIZE} />)
  },
  {
    title: 'Sites',
    path: URLS.SITES,
    component: <Sites />,
    icon: (<MapPinIcon size={ICON_SIZE} />)
  },
  {
    title: 'Accesses',
    path: URLS.ACCESSES,
    component: <Accesses />,
    icon: (<BoxIcon size={ICON_SIZE} />)
  },
  {
    title: 'Activities',
    path: URLS.ACTIVITIES,
    component: <Activities />,
    icon: (<BoxIcon size={ICON_SIZE} />)
  },
  {
    title: 'Discounts',
    path: URLS.DISCOUNTS,
    component: <Discounts />,
    icon: (<BoxIcon size={ICON_SIZE} />)
  },
  // {
  //   title: 'Calculator',
  //   href: URLS.GOLF_SIMULATOR_CALCULATOR,
  //   header: true,
  //   component: GolfSimulatorCalculator,
  //   icon: <DialpadIcon style={MaterialUIIconStyle} />
  // },
  {
    title: 'Settings',
    path: `/settings`,
    component: <Settings />,
    icon: (<SettingsIcon size={ICON_SIZE} />)
  }
];

const tabs = routes.filter((route) => route.header !== false);

export default function App() {
  const { isLoading, isAuthenticated, error: authError } = useAuth0();
  
  const app = useSelector(state => state.app);

  const sidenavShowRef = useRef();

  if (app.error || authError) {
    return (
      <ErrorPage error={app.error || authError} />
    );
  }

  if (isLoading) {
    return null;
    // return (
    //   <Loading title={app.loadingText} animate={true} />
    // );
  }

  if (!isAuthenticated) {
    return (
      <Login />
    );
  }

  return (
    <section className="app dark:text-gray-200">
      <SidebarNav routes={tabs} showRef={sidenavShowRef} />

      <main className="flex flex-col overflow-hidden flex-1">
        <Header tabs={tabs} onOpenRef={sidenavShowRef} />
        <React.Suspense fallback={<div></div>}>
          <Routes>
            {routes.map((route, idx) => (
              <Route key={idx} path={`${route.path}/*`} element={route.component} />
            ))}

            <Route path="*" element={<Navigate replace to={URLS.USERS} />} />
          </Routes>
        </React.Suspense>
      </main>
    </section>
  );
}