import { useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Menu as MenuIcon } from 'react-feather';
import { useSelector } from 'react-redux';
import { getHeaderAction } from '../reducers/selectors';
import HeaderButton, { BackHeaderButton } from '../components/HeaderButton';
import { useMedia } from 'react-use';
import './Header.css';


function ActionButton({ action }) {
  const actionButton = useMemo(() => {
    if (!action) return null;

    if (action.isBack) {
      return (
        <BackHeaderButton
          url={action.url}
          onClick={action.fn}
          title={action.title}
        />
      );
    } else {
      return (
        <HeaderButton onClick={action.fn}>
          {action.title}
        </HeaderButton>
      );
    }
  }, [action]);

  return actionButton;
}


export default function Header({ tabs, onOpenRef }) {
  const isSmallScreen = useMedia('(max-width:540px)');
  const headerAction = useSelector(getHeaderAction);

  const location = useLocation();

  const activeTab = useMemo(() => {
    const href = location.pathname;
    return tabs.find(t => t.href === href);
  }, [tabs, location]);

  const title = activeTab ? activeTab.title : '';

  const hasLogoOnClickRef = useRef(false);

  hasLogoOnClickRef.current = !!(activeTab && activeTab.logoHref);

  return (
    <div className="z-10 flex flex-col flex-shrink-0 m-0 p-0 relative">
      <div className={"flex-none pl-1 " + (isSmallScreen ? (headerAction ? "h-23" : "h-14") : headerAction ? "h-9" : "h-0")}>
        <header className="flex flex-col flex-wrap font-semibold shadow">
          {isSmallScreen && (
            // <div className="flex items-center justify-between h-14 px-1">
            <div className="header-content h-14 px-2">
              <div className="header-content-first">
                <button className="btn btn-link-primary focus:ring-0" onClick={() => onOpenRef.current(true)}>
                  <MenuIcon color="var(--color-dark-gray)" />
                </button>
              </div>
              

              <div className="text-xl font-semibold select-none text-center">
                {title}
              </div>

              <div></div>
            </div>
          )}
          
          <ActionButton action={headerAction} />
        </header>
      </div>
    </div>
  );
}