import { useAuth0 } from '@auth0/auth0-react';

export default function LogoutButton(props) {
  const { logout } = useAuth0();

  return (
    <button
      className="btn btn-outlined-primary"
      onClick={() => logout({ returnTo: window.location.origin })}
      {...props}
    >
      Log Out
    </button>
  );
}


// import { useDispatch } from 'react-redux';
// import * as AppActions from '../actions/app';

// export default function LogoutButton(props) {
//   const dispatch = useDispatch();

//   return (
//     <button
//       className="btn btn-outlined-primary"
//       onClick={() => dispatch(AppActions.logout())}
//       {...props}
//     >
//       Log Out
//     </button>
//   );
// }