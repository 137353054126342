import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

export default function HeaderButton({ children, className = "", ...props }) {
  return (
    <button
      className={"btn-contained-primary rounded-none rounded-bl-md " + className}
      {...props}
    >
      {children}
    </button>
  );
}

export function BackHeaderButton({ url, onClick, title }) {
  const navigate = useNavigate();

  const onGoBack = useCallback(() => {
    if (url) {
      navigate(url);
    } else {
      navigate(-1);
    }
  }, [navigate, url]);

  return (
    <HeaderButton
      className="flex justify-start items-center"
      onClick={onClick || onGoBack}
    >
      <FontAwesomeIcon icon={faChevronLeft} />
      <span className='ml-2'>{title || 'Go Back'}</span>
    </HeaderButton>
  );
}