import MainLayout from '../components/MainLayout';
import Branding from '../components/Branding';
import LogoutButton from '../components/LogoutButton';


function reload() {
  window.location.reload();
}


export default function ErrorContainer({ error }) {
  return (
    <MainLayout
      Header={{ className: "justify-between" }}
      card
      header={(
        <>
          <Branding logo={true} name={true} />
          <LogoutButton />
        </>
      )}
      title={(
        <>
          <div className="text-4xl font-bold">Error</div>
          <div className="text-xl text-gray-500">{error?.message ? error?.message : error}</div>
        </>
      )}
      content={(
        <>
          <div className="text-xl text-center">
            <div>Please refresh</div>
            <button
              className="btn-contained-primary"
              onClick={reload}
            >
              Refresh
            </button>
          </div>
        </>
      )}
    />
  );
}