import Logo from '../icons/Chipper';
import BrandName from './BrandName';

export default function Branding({ logo = false, name = false, logoProps = {}, nameProps = {}, className, ...props }) {
  return (
    <span className={"flex items-center " + (className || '')} {...props}>
      {logo && (
        <Logo size={42} {...logoProps} />
      )}

      {name && (
        <div className={logo ? "ml-4" : ""}>
          <BrandName {...nameProps} />
        </div>
      )}
    </span>
  );
}