export default function Chipper({ color = 'currentColor', size = 24, ...props }) {
  return (
    <svg {...props} width={size} height={size} viewBox="0 0 1450 1450" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path
          d="M 1448.9399 915.1247 C 595.3309 1497.6552 -13.5659 1159.837 1.2651 712.4338 C 19.8721 151.1225 849.9305 144.7343 1087.2272 344.1295 C 888.656 380.3832 760.1203 430.6439 709.8595 487.4963 C 744.4653 566.0459 791.1556 694.0323 849.9305 871.4555 C 1085.03 838.4977 1284.6998 853.0541 1448.9399 915.1247 Z M 517.8799 293.0448 C 174.294 397.686 108.0494 758.6532 217.9632 971.1531 C 304.4776 1138.4144 599.4506 939.8431 793.0782 891.2303 C 776.5992 723.969 689.2608 494.0878 517.8799 293.0448 Z"
          fill={color}
        >
        </path>
      </g>
    </svg>
  );
}