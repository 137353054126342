import { action } from './common';

const BASE = 'SOCKETIO/';

export const MESSAGE = `${BASE}MESSAGE`;
export const message = (message) => action(MESSAGE, message);


export const OPEN = `${BASE}OPEN`;
export const CLOSED = `${BASE}CLOSED`;
export const ERROR = `${BASE}ERROR`;
export const CONNECTING = `${BASE}CONNECTING`;
export const DISCONNECTING = `${BASE}DISCONNECTING`;

export const open = () => action(OPEN);
export const closed = () => action(CLOSED);
export const error = (error) => action(ERROR, error);
export const connecting = () => action(CONNECTING);
export const disconnecting = (reason) => action(DISCONNECTING, reason);