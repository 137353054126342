import { toQueryString } from './helpers';

const BASE_URL = process.env.REACT_APP_API_BASE_URL || '/api/account';

let getAccessTokenSilently;
export function setgetAccessTokenSilently(fn) {
  getAccessTokenSilently = fn;
}

export async function getAccessToken() {
  return await getAccessTokenSilently();
}

/**
 * @param {string} url
 */
async function APIRequest(url, options) {
  const opts = {
    ...options,
    headers: {
      ...options?.headers,
    },
  };

  const token = await getAccessTokenSilently();
  if (token) {
    opts.headers['Authorization'] = `Bearer ${token}`;
  }

  if (typeof opts.body === 'object' && !(opts.body instanceof FormData)) {
    opts.headers['Content-Type'] = 'application/json';
    opts.body = JSON.stringify(opts.body);
  }

  const res = await fetch(url, opts);

  const text = await res.text();

  if (!res.ok) {
    const error = new Error(text);
    error.code = res.status;
    throw error;
  }

  return JSON.parse(text);
}

export { APIRequest as Request };



export function retrieveInternalMetrics() {
  return APIRequest(BASE_URL + '/v1/internal/metrics', undefined, 20000);
}

/**
 * @param {{
 *   start_date: Date,
 *   end_date: Date,
 *   temporal_unit: 'day' | 'month',
 * }} param0
 */
export function retrieveInternalTotalCheckInMetrics({ start_date, end_date, temporal_unit }) {
  const query = toQueryString({
    start_date, end_date, temporal_unit,
  });

  return APIRequest(BASE_URL + `/v1/metrics/total_checkins${query}`);
}


export function listUsers({
  disabled, membership_picture_approved,
  search, limit, offset,
}) {
  const query = toQueryString({
    disabled, membership_picture_approved,
    search, limit, offset,
  });

  return APIRequest(BASE_URL + `/v1/users${query}`);
}

export function updateUser(uid, payload) {
  return APIRequest(BASE_URL + `/v1/users/${uid}`, {
    method: 'PATCH',
    body: payload
  });
}

export function uploadUserProfilePicture(uid, file) {
  const formData = new FormData();
  formData.append('picture', file, file.name);

  return APIRequest(BASE_URL + `/v1/users/${uid}/profile-picture`, {
    method: 'POST',
    body: formData
  });
}

export function updateCheckIn(uid, payload) {
  return APIRequest(BASE_URL + `/v1/checkins/${uid}`, {
    method: 'PATCH',
    body: payload
  });
}

export function retrieveUser(uid) {
  return APIRequest(BASE_URL + `/v1/users/${uid}`);
}

export function retrieveCheckIn(uid) {
  return APIRequest(BASE_URL + `/v1/checkins/${uid}`);
}



export function retrieveSite(uid) {
  return APIRequest(BASE_URL + `/v1/sites/${uid}`);
}

export function updateSite(uid, payload) {
  return APIRequest(BASE_URL + `/v1/sites/${uid}`, {
    method: 'PATCH',
    body: payload
  });
}

/**
 * @param {{
 *   user: string,
 *   access: string,
 *   addons: string[],
 *   preview: boolean,
 * }} param0
 */
export function createSubscription({
  user,
  access,
  addons,
  preview
}) {
  return APIRequest(BASE_URL + `/v1/subscriptions`, {
    method: 'POST',
    body: {
      user,
      access,
      addons,
      preview
    }
  });
}


export function listSubscriptions({
  user,
  search, limit, offset,
}) {
  const query = toQueryString({
    user,
    search, limit, offset,
  });

  return APIRequest(BASE_URL + `/v1/subscriptions${query}`);
}

export function getSubscription(uid) {
  return APIRequest(BASE_URL + `/v1/subscriptions/${uid}`);
}

export function updateSubscription(uid, payload) {
  return APIRequest(BASE_URL + `/v1/subscriptions/${uid}`, {
    method: 'PATCH',
    body: payload
  });
}

export function pauseSubscription(uid, payload) {
  return APIRequest(BASE_URL + `/v1/subscriptions/${uid}/pause`, {
    method: 'POST',
    body: payload
  });
}

export function unpauseSubscription(uid, { user, preview, force } = {}) {
  return APIRequest(BASE_URL + `/v1/subscriptions/${uid}/unpause`, {
    method: 'POST',
    body: { user, preview, force }
  });
}

/**
 * @param {string} uid
 * @param {*} payload
 */
export function cancelSubscriptionAtPeriodEnd(uid, payload = {}) {
  return APIRequest(BASE_URL + `/v1/subscriptions/${uid}/cancel`, {
    method: 'POST',
    body: payload,
  });
}

/**
 * @param {string} uid
 * @param {*} payload
 */
export function cancelSubscriptionImmediately(uid, payload = {}) {
  return APIRequest(BASE_URL + `/v1/subscriptions/${uid}/cancel-immediately`, {
    method: 'POST',
    body: payload,
  });
}

/**
 * @param {string} uid
 * @param {*} payload
 */
export function uncancelSubscription(uid, payload = {}) {
  return APIRequest(BASE_URL + `/v1/subscriptions/${uid}/uncancel`, {
    method: 'POST',
    body: {
      ...payload
    }
  });
}


export function listBusinesses({
  active,
  search, limit = 100, offset,
}) {
  const query = toQueryString({
    active,
    search, limit, offset,
  });

  return APIRequest(BASE_URL + `/v1/businesses${query}`);
}

/**
 * @param {string} uid
 */
export function retrieveBusiness(uid) {
  return APIRequest(BASE_URL + `/v1/businesses/${uid}`);
}

/**
 * @param {string} uid
 * @param {*} payload
 */
export function updateBusiness(uid, payload) {
  return APIRequest(BASE_URL + `/v1/businesses/${uid}`, {
    method: 'PATCH',
    body: payload
  });
}

export function listSites({
  active,
  business, location, radius, network, activity_type,
  search, limit = 100, offset,
}) {
  const query = toQueryString({
    active,
    business, location, radius, network, activity_type,
    search, limit, offset,
  });

  return APIRequest(BASE_URL + `/v1/sites${query}`);
}

export function siteMetrics(siteUID) {
  return APIRequest(BASE_URL + `/v1/sites/${siteUID}/metrics`);
}

export function siteCheckins(site, { search, limit = 25, } = {}) {
  const query = toQueryString({
    site, search, limit,
  });
  return APIRequest(BASE_URL + `/v1/checkins${query}`);
}


export function listCheckIns({
  site, user,
  search, limit = 25, offset,
}) {
  const query = toQueryString({
    site, user,
    search, limit, offset,
  });
  return APIRequest(BASE_URL + `/v1/checkins${query}`);
}


export function listNetworks({
  active,
  search, limit = 25, offset,
}) {
  const query = toQueryString({
    active,
    search, limit, offset,
  });
  return APIRequest(BASE_URL + `/v1/networks${query}`);
}

export function retrieveNetwork({
  uid
}) {
  return APIRequest(BASE_URL + `/v1/networks/${uid}`);
}



export function retrieveActivity(uid) {
  return APIRequest(BASE_URL + `/v1/activities/${uid}`);
}

export function updateActivity(uid, data) {
  return APIRequest(BASE_URL + `/v1/activities/${uid}`, {
    method: 'PATCH',
    body: data
  });
}

export function listActivities({
  active,
  network,
  search, limit = 25, offset,
}) {
  const query = toQueryString({
    active,
    network,
    search, limit, offset,
  });
  return APIRequest(BASE_URL + `/v1/activities${query}`);
}



export async function retrieveAccess(uid) {
  return APIRequest(BASE_URL + `/v1/accesses/${uid}`);
}

export function listAccesses({
  active, open,
  network, activity, activity_type,
  search, limit = 25, offset,
}) {
  const query = toQueryString({
    active, open,
    network, activity, activity_type,
    search, limit, offset,
  });
  return APIRequest(BASE_URL + `/v1/accesses${query}`);
}

export function updateAccess(uid, data) {
  return APIRequest(BASE_URL + `/v1/accesses/${uid}`, {
    method: 'PATCH',
    body: data
  });
}


export function listAddons({
  active, ref,
  network, activity_type, access,
  search, limit = 25, offset,
}) {
  const query = toQueryString({
    active, ref,
    network, activity_type, access,
    search, limit, offset,
  });
  return APIRequest(BASE_URL + `/v1/addons${query}`);
}


// export async function uploadUserProfilePicture({ user, file }) {
//   const formData = new FormData();
//   formData.append('picture', file, file.name);

//   // const hash = await Blurhash.encode(URL.createObjectURL(file));
//   // formData.append('blurhash', hash);

//   return APIRequest(BASE_URL + `/v1/users/${user}/profile_image`, {
//     method: 'POST',
//     body: formData
//   });
// }


export async function deleteUserProfilePicture({ user }) {
  return APIRequest(BASE_URL + `/v1/users/${user}/profile_image`, {
    method: 'DELETE'
  });
}


export async function listSiteActivities({
  active,
  site, business, activity_type,
  search, limit = 25, offset,
}) {
  const query = toQueryString({
    active,
    site, business, activity_type,
    search, limit, offset,
  });
  return APIRequest(BASE_URL + `/v1/siteactivities${query}`);
}


export async function updateSiteActivity(uid, data) {
  return APIRequest(BASE_URL + `/v1/siteactivities/${uid}`, {
    method: 'PATCH',
    body: data
  });
}


export async function listDiscounts({
  active,
  search, limit, offset,
} = {}) {
  const query = toQueryString({
    active,
    search, limit, offset,
  });
  return APIRequest(BASE_URL + `/v1/discounts${query}`);
}

export function retrieveDiscount(uid) {
  return APIRequest(BASE_URL + `/v1/discounts/${uid}`);
}

export function updateDiscount(uid, payload) {
  return APIRequest(BASE_URL + `/v1/discounts/${uid}`, {
    method: 'PATCH',
    body: payload
  });
}


export async function listBusers({
  active, business,
  search, limit, offset,
} = {}) {
  const query = toQueryString({
    active, business,
    search, limit, offset,
  });
  return APIRequest(BASE_URL + `/v1/busers${query}`);
}

export function retrieveBuser(uid) {
  return APIRequest(BASE_URL + `/v1/busers/${uid}`);
}

export function updateBuser(uid, payload) {
  return APIRequest(BASE_URL + `/v1/busers/${uid}`, {
    method: 'PATCH',
    body: payload
  });
}


export async function listActivityTypes({
  active,
  search, limit, offset,
} = {}) {
  const query = toQueryString({
    active,
    search, limit, offset,
  });
  return APIRequest(BASE_URL + `/v1/activitytypes${query}`);
}


export function checkInAddGolfCart(uid, input) {
  return APIRequest(BASE_URL + `/v1/checkins/${uid}/add_golf_cart`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(input),
  });
}