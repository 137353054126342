export function redirectTo(url) {
  window.location.href = url;
}

function devicePixelRatio() {
  const ratio = window.devicePixelRatio;
  if (Number.isFinite(ratio)) {
    return ratio;
  }
  return null;
}

export function getIKSmartSizeImageURL(url, width = 156, height = 156) {
  url = new URL(url);
  const pixelRatio = devicePixelRatio();
  url.searchParams.append('tr', `w-${width},h-${height}${pixelRatio ? `,dpr-${pixelRatio}` : ''}`);
  return url.toString();
}

export function toQueryString(params, doNotEncode) {
  return '?' + Object.keys(params)
    .sort()
    .map((key) => {
      const value = params[key];
      if (value != null && value !== '') {
        if (doNotEncode === true) {
          return `${key}=${value}`;
        }
        return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
      }
      return null;
    }).filter(v => !!v).join('&');
}

function __updaterGetObject(obj, keys, update) {
  let _obj = obj;
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    if (_obj[key] == null) {
      if (update) {
        _obj[key] = {};
      } else {
        return _obj[key];
      }
    }
    _obj = _obj[key];
  }
  return _obj;
}

function Object_is(x, y) {
  if (x === y) {
    // 0 === -0, but they are not identical
    return x !== 0 || 1 / x === 1 / y;
  }

  // NaN !== NaN, but they are identical.
  // NaNs are the only non-reflexive value, i.e., if x !== x,
  // then x is a NaN.
  // isNaN is broken: it converts its argument to number, so
  // isNaN("foo") => true
  return x !== x && y !== y; // eslint-disable-line no-self-compare
}

function isEqual(a, b) {
  if (Object_is(a, b)) {
    return true;
  }

  if (a === null || b === null) {
    return a === b;
  }

  const className = Object.prototype.toString.call(a);
  if (className !== Object.prototype.toString.call(b)) {
    return false;
  }

  switch (className) {
    // Strings, numbers, dates, and booleans are compared by value.
    case '[object String]':
      // Primitives and their corresponding object wrappers are equivalent; thus, `"5"` is
      // equivalent to `new String("5")`.
      return a == String(b); // eslint-disable-line eqeqeq
    case '[object Number]':
      return Object_is(Number(a), Number(b));
    case '[object Date]':
    case '[object Boolean]':
      // Coerce dates and booleans to numeric primitive values. Dates are compared by their
      // millisecond representations. Note that invalid dates with millisecond representations
      // of `NaN` are not equivalent.
      return +a === +b;
    case '[object RegExp]':
      // RegExps are compared by their source patterns and flags.
      return (
        a.source === b.source &&
        a.global === b.global &&
        a.multiline === b.multiline &&
        a.ignoreCase === b.ignoreCase
      );
    default:
      //
  }

  if (typeof a !== 'object' || typeof b !== 'object') {
    return false;
  }

  return a === b;
}

function getValueDescription(value) {
  if (value instanceof Date) {
    console.log(value);
    return value.toLocaleString();
  } else {
    return value;
  }
}

export function updater(data, mappings, values) {
  const changes = [];
  const updated = {};

  for (const mapping of mappings) {
    let keys;
    let transform;
    if (Array.isArray(mapping)) {
      keys = mapping[0];
      transform = mapping[1];

      if (!Array.isArray(keys)) {
        keys = keys.split('.');
      }
    } else if (typeof mapping === 'string') {
      keys = mapping.split('.');
    } else {
      keys = mapping.key.split('.');
      transform = mapping.transform;
    }

    let current = __updaterGetObject(data, keys);
    let next = __updaterGetObject(values, keys);

    if (!(next === '' && current === null) && !isEqual(current, next)) {
      const updatedObj = __updaterGetObject(updated, keys.slice(0, keys.length - 1), true);
      changes.push(`${keys.join('.')}: ${getValueDescription(current)} → ${getValueDescription(next)}`);

      if (typeof transform === 'function') {
        next = transform(next);
      }
      updatedObj[keys[keys.length - 1]] = next;
    }
  }

  return {
    changes,
    updated
  };
}

export function flattenObject(obj, base = '') {
  const flattened = {};

  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      Object.assign(flattened, flattenObject(obj[key], base + key + '.'));
    } else {
      flattened[base + key] = obj[key];
    }
  });

  return flattened;
}

/**
 * delay
 * @param {number} ms 
 * @returns Promise<void>
 */
export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const getPriceAmount = (entity) => entity.price_config?.unit_amount;
export const getPriceInterval = (entity) => entity.price_config?.recurring?.interval;
export const getPriceIntervalCount = (entity) => entity.price_config?.recurring?.interval_count;
export const getPriceCurrency = (entity) => entity.price_config?.currency;